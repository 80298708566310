import React from 'react';
import Brcrumb from '../../components/Book/Brcrumb';
import BookItemBlock from '../../components/Book/BookItemBlock';
import BookDetails from '../../components/Book/BookDetails';
import PropTypes from 'prop-types';
import SearchBar from '../../components/Sidebar/SearchBar';
import SidebarCategory from '../../components/Sidebar/SidebarCategory';
import CategoryGrid from '../../components/Category/CategoryGrid';
import BookItem from '../../components/BooksGrid/BookItem';
import BookSidebar from '../../components/Sidebar/BookSidebar';
import TopAuthors from '../../components/Sidebar/TopAuthors';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import SideBarList from '../../components/Sidebar/SideBarList';

export const AboutDetails = () => {
    return (
    

            <div className="main-content  category-page">


                <div className="content-block padding-zero">
                    <div className="block-wrap">
                        <div className="block">

                            <div className="tab-wrap module type-book grid">

                                <div className="col-xs-12 newstext ">
                                    <h3>
                                        <div
                                            className="item-excerpt trunc" >
                                        </div>
                                    </h3>

                                    <div
                                        className="item-excerpt trunc article-body" >

                                    <h1>Общество с ограниченной ответственностью «Татарское книжное издательство»</h1>
                                    <h2>ООО «Таткнигоиздат»</h2>
                                    <p><strong>Генеральный директор:</strong> Сагдатшин Ильдар Камилевич</p>
                                    <p><strong>Приемная тел.:</strong> (843) 519–45–22</p>
                                    <h3>Основные реквизиты:</h3>
                                    <ul>
                                        <li><strong>ИНН:</strong> 1655506417</li>
                                        <li><strong>КПП:</strong> 165501001</li>
                                        <li><strong>ОКВЭД:</strong> 58.11</li>
                                        <li><strong>ОКПО:</strong> 82492375</li>
                                        <li><strong>ОКОГУ:</strong> 4210014</li>
                                        <li><strong>ОКАТО:</strong> 92401367000</li>
                                        <li><strong>ОКТМО:</strong> 92701000001</li>
                                        <li><strong>ОКФС:</strong> 13</li>
                                        <li><strong>ОКОПФ:</strong> 12300</li>
                                        <li><strong>ОГРН:</strong> 1241600061504</li>
                                    </ul>
                                    <h3>Адреса:</h3>
                                    <p><strong>Юридический адрес:</strong> 420111, г. Казань, ул. Баумана, д. 19</p>
                                    <p><strong>Фактический адрес:</strong> 420066, г. Казань, ул. Декабристов, д. 2</p>
                                    <h3>Банковские реквизиты:</h3>
                                    <p><strong>Банк:</strong> ПАО «Ак Барс» банк</p>
                                    <p><strong>Расчетный счет:</strong> 40602810445020003105</p>
                                    <p><strong>Корреспондентский счет:</strong> 30101810000000000805</p>
                                    <p><strong>БИК:</strong> 049205805</p>
                                    <p>
                                        <a href="/upload/details.pdf" download="details.pdf">
                                        Скачать реквизиты 
                                        </a>
                                        </p>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
     
        
        
        )
}