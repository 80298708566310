import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { YMaps, Map, Placemark } from "react-yandex-maps";
import { Helmet } from "react-helmet";
import './stores.css';

const mapData = {
    center: [55.796127, 49.106414],
    zoom: 12,
};



const OurStores = () => {

    const [text, setText] = useState(null);
    const [bounds, setBounds] = useState(null);
    const routes = useRef();
    const mapRef = useRef(null);
    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.setBounds(bounds);
          
        }
    }, [bounds]);

    return (

        <>
            <Helmet>
                <title>Таткнига - фирменные магазины</title>
                <meta name="description" content="Таткнига - адреса фирменных магазинов " />
            </Helmet>

            <div class="mainbox">

                <YMaps>
                    <Map defaultState={mapData} width='100%' height='400px'
                        modules={[
                            "multiRouter.MultiRoute",
                            "coordSystem.geo",
                            "geocode",
                            "util.bounds"
                        ]}

               
                    >
                       
              

                        <Placemark
                            geometry={[55.815029, 49.102955 ]}
                            properties={{
                                balloonContentBody: "Магазин №2, г.Казань, ул.Декабристов, д.2 <br/>  (Будние дни 08:00 — 18:00 )"
                            }}
                            onClick={() => setText("Магазин №2, г.Казань, ул.Декабристов, д.2 <br/>  тел.:  +7 (927) 671-05-00  (Будние дни 08:00 — 18:00 )")}
                              
                        />
                        <Placemark
                            geometry={[55.791319, 49.114158]}
                            properties={{
                                balloonContentBody: "Магазин №3, г.Казань, ул.Баумана 19, <br/>  тел.: (843) 294-70-50  <br/>  (Понедельник — Воскресенье 10:00 — 20:00)"
                            }}
                            onClick={() => setText("Магазин №3, г.Казань, ул.Баумана 19, <br/>  тел.: (843) 294-70-50  <br/>   (Понедельник — Воскресенье 10:00 — 20:00)")}

                        />
                        
                      
                        
                    </Map>
                </YMaps>
         


            </div>
        </>
    );
}

export default OurStores;
